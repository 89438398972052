var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticStyle:{"align-items":"center","padding":"150px 0"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"col-lg-6 col-md-8"},[_c('table',{staticStyle:{"box-sizing":"border-box","width":"100%","border-radius":"6px","overflow":"hidden","background-color":"#fff","box-shadow":"0 0 3px rgba(60, 72, 88, 0.15)"}},[_vm._m(0),_c('tbody',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('tr',[_c('td',{staticStyle:{"padding":"16px 8px","color":"#8492a6","background-color":"#f8f9fc","text-align":"center"}},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Landrick. ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticStyle:{"background-color":"#2f55d4","padding":"3px 0","line-height":"68px","text-align":"center","color":"#fff","font-size":"24px","font-weight":"700","letter-spacing":"1px"}},[_c('th',{attrs:{"scope":"col"}},[_c('img',{attrs:{"src":"images/logo-light.png","height":"32","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"padding":"48px 24px 0","color":"#161c2d","font-size":"18px","font-weight":"600"}},[_vm._v(" Hello, Harry ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"padding":"15px 24px 15px","color":"#8492a6"}},[_vm._v(" Thanks for creating an Landrick account. To continue, please confirm your email address by clicking the button below : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"padding":"15px 24px"}},[_c('a',{staticClass:"btn-primary",staticStyle:{"padding":"8px 20px","outline":"none","text-decoration":"none","font-size":"16px","letter-spacing":"0.5px","transition":"all 0.3s","font-weight":"600","border-radius":"6px"},attrs:{"href":"javascript:void(0)"}},[_vm._v("Confirm Email Address")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"padding":"15px 24px 0","color":"#8492a6"}},[_vm._v(" This link will be active for 30 min from the time this email was sent. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"padding":"15px 24px 15px","color":"#8492a6"}},[_vm._v(" Landrick "),_c('br'),_vm._v(" Support Team ")])])
}]

export { render, staticRenderFns }